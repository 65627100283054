/* You can add global styles to this file, and also import other style files */
//@import "~leaflet/dist/leaflet.css";
//@import '~saturn-datepicker/theming';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$candy-app-primary: mat-palette($mat-blue);
$candy-app-accent:  mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);

//@include sat-datepicker-theme($candy-app-theme);

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; /*background-color: #edeff3*/ }

.attr-visibility-hidden {
  visibility: hidden;
}

alert { position: absolute; z-index: 10000; width: 50%; transform: translateX(-50%); left: 50%; }
alert button { position: absolute; right: 10px; top: 50%; transform: translateY(-50%); border: none; background: none; }

.app-widget { display: block; height: 100%; position: relative; }
app-chart,
app-table { display: block; height: 100%; position: relative; }
app-chart .line-chart,
app-table .table-chart { display: block; height: calc(100% - 50px); width: 100%; }
app-table .google-visualization-table { width: 100% }


.global-date .md-drppicker.ltr { width: 650px; right: 20px !important; left: initial !important; }

.dropdown-menu.dropdown-menu-right.show { z-index: 1001; }

#home-map-button { padding: 0; }
#home-map-button .target { font-size: 29px; width: 30px; }

